<template>
  <div class="answer-sheet">
    <div class="title">
      <div class="wrap">
        答题卡
      </div>
    </div>
    <div class="content">
      <!-- <div v-for="(group,index) in paperData" :key="index" class="group"> -->
      <div v-for="(group,index) in paperData" :key="index" class="group">
        <div class="label">
          {{questionTypeMap[group[0].type]}}
        </div>
        <ul class="clearfix">
          <li v-for="(item,index) in group" :key="item.id" :question_id="item.question_id" :class="{show:true,correct:item.is_right==1,wrong:item.is_right==0}" @click="selectAnswer(item,group)">
            <div class="wrap">{{index+1}}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="action">
      <button v-if="!resultVisible" v-loading="submitLoading" class="btn" @click="turnIn">交卷</button>
      <div v-else class="legend clearfix">
        <div class="item wrong">
          <span class="tag"></span>
          <span class="">错题</span>
        </div>
        <div class="item correct">
          <span class="tag"></span>
          <span class="">答对</span>
        </div>
        <div class="item">
          <span class="tag"></span>
          <span class="">未答</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dic from '@/util/dic'
import { getPaper } from '@/api/exam'
// 1: 单选题 2: 复选题 3:名词解释 4:计算题 5: 简答题 6: 论述题 7:判断题 8:判断不定项选择题 9:分析题
//问题跟着答题卡走
export default {
  data() {
    return {
      submitLoading: false,
      questionTypeMap: dic.questionTypeMap,
      paperData: [],//根据typeList排列的二维数组
      paperList: [],
      typeList: [1, 2, 5, 4, 6, 3, 7, 8, 9],//选择，简答，计算，论述
      exam_info: {},
      total_score: 0,
      selectQusetionSort: [],
      paperSort:[]//排序数组
    }
  },
  props: {
    exam_id: String | Number,
    version: String,
    resultVisible: Boolean,
    submitStatus: Boolean,
    questionList: {
      type: Array,
      default: () => []
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      await this.getPaperList()
      //拿第一题
      let group = this.paperData[0] || []
      let answer = group[0] || {}
      let groupData = this.getGroupData(answer, group)
      this.selectAnswer(group[0] || {}, group)
      this.$emit('answerSheetInited', {
        paperData: this.paperData,
        groupData,
        answer
      })
      console.log(this.paperData, "::::-paperData--")
    },
    async getPaperList() {
      const { data } = await getPaper({
        uniqid: this.$store.getters.uniqid,
        exam_id: this.exam_id,
        version: this.version,
      })
      this.paperList = data.list || [];
      this.exam_info = data.exam_info
      this.total_score = data.total_score

      this.setPeperData()
    },
    setPeperData() {
      this.paperData = []
      let list = [].concat(this.paperList)
      let index = 0;
      this.typeList.forEach((type) => {
        for (let i = 0; i < list.length; i++) {
          let item = list[i]
          if (item.type == type) {
            if (this.paperData[index]) {
              this.paperData[index].push(item)
            } else {
              this.$set(this.paperData, index, [item])
            }
            list.splice(i, 1);
            i--
            if (list.length < 1) {
              item.isLast = true
            }
          } else {
            //todo  typeList没有的,正常来说不会出现
          }

        }

        if (this.paperData[index] && this.paperData[index].length > 0) {
          index++
        }
      })
      this.questionSort()
    },
    getGroupData(answer, group) {
      return {
        label: this.questionTypeMap[answer.type],
        index: group.findIndex(item => item.question_id === answer.question_id),
        length: group.length,
        value: group
      }
    },
    selectAnswer(answer, group) {
      //返回分组信息
      let groupData = this.getGroupData(answer, group)
      this.$emit('selectAnswer', { answer, groupData })
    },
    turnIn() {
      //提交试卷
      if (this.paperList.every(item => !!item.user_answer)) {
        //全部作答
        this.submit()
      } else {
        this.$confirm('您尚未完成全部题目', '', {
          confirmButtonText: '结束答题',
          cancelButtonText: '继续答题',
          type: 'warning'
        }).then(() => {
          this.submit()
        }).catch(() => {

        });
      }
    },
    async submit() {
      await this.getPaperList()
      this.$emit('getResultSuccess', this.total_score)
    },
    toNext(question_id) {
      for (let i = 0; i < this.paperData.length; i++) {
        let group = this.paperData[i];
        for (let j = 0; j < group.length; j++) {
          if (group[j].question_id === question_id) {
            if (j < group.length - 1) {
              this.selectAnswer(group[j + 1], group);
            } else {
              //本组最后一道题，需进到下一组
              if (i < this.paperData.length - 1) {
                let g = this.paperData[i + 1];
                if (g[0]) {
                  this.selectAnswer(g[0], g);
                }
              } else {
                //已经是最后一组的最后一道题
              }
            }
            break;
          }
        }
      }
    },
    questionSort() {//问题顺序
      let paperData = this.paperData;
      let arr = []
      let sort = this.paperSort
      if(paperData.length) {
        // 答题卡左侧提顺序
        sort.forEach(type => {
          for(let i = 0, len = paperData.length; i < len; i++) {
            let t = paperData[i][0].type
            if(t === type) {
              arr.push(paperData[i])
              break
            }
          }
        })
        // 答题卡具体题顺序
        
        let newArr = []// 所有题正确展示顺序
        let index = 0;
          let sortArr = []//存放 某一题型 的展示顺序
        arr.forEach(item => {
          for(let i = index,len=this.questionList.length; i<len;i++) {
            let isBreak = false
            for(let j = 0,len2=item.length;j<len2;j++) {
              let obj = this.questionList[i]
              let obj2 = item[j]
              if(obj.question.type == obj2.type && obj.question_id == obj2.question_id) {
                // 同一类型下的 同一题
                sortArr.push(obj2)
              } else if(obj.question.type != obj2.type) {//非同一类型
                newArr.push(sortArr)
                isBreak = true
                sortArr = []
                break
              }
            }
            if(isBreak) {
              index=i//优化循环-questionList 只需要遍历一遍
              break
            }
          }
        })
        newArr.push(sortArr)
        this.paperData = newArr;
        console.log(this.paperData, "::::this.paperData:::::sort999")
      }
    }
  },
  watch: {
    questionList: {
      handler(val) {
        let arr = []
        let type = -100
        val.forEach(item => {
          let t = item.question.type
          if(type !== t) {
            arr.push(t)
            type = t
          }
        })
        this.paperSort = [...arr]
        this.questionSort()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style lang="scss" scoped>
.answer-sheet {
  background: #ffffff;
  border-radius: 10px;
  .title {
    padding: 0 20px;
    .wrap {
      padding: 22px 10px;
      font-size: 24px;
      font-weight: 500;
      border-bottom: 1px solid $borderColor;
    }
    .right {
      float: right;
      line-height: 28px;
      font-size: 18px;
      img.icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        vertical-align: middle;
      }
    }
  }
  .content {
    min-height: 658px;
  }
  .action {
    padding: 17px 20px;
    border-top: 1px solid $borderColor;
    .btn {
      width: 160px;
      height: 36px;
      background: $mainColor;
      border-radius: 18px;
      color: #fff;
      font-weight: 500;
      cursor: pointer;
    }
    .icon {
      height: 18px;
      vertical-align: middle;
    }

    .legend {
      padding: 0 20px;
      .item {
        float: left;
        width: percentage($number: 1/3);
        text-align: center;
        font-size: 14px;
        line-height: 30px;
        span {
          vertical-align: middle;
        }
        .tag {
          display: inline-block;
          margin-right: 10px;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          border: 1px solid $borderColor;
        }
        &.wrong {
          color: $color-red;
          .tag {
            border-color: $color-red;
            background-color: $color-red;
          }
        }
        &.correct {
          color: $color-green;
          .tag {
            border-color: $color-green;
            background-color: $color-green;
          }
        }
      }
    }
  }
  .content {
    padding: 0 20px;
    min-height: 658px;
  }
  .group {
    .label {
      padding: 18px 0;
      font-size: 18px;
      font-weight: 500;
    }
    ul {
      margin: 0 -10px;
    }
    li {
      float: left;
      margin-bottom: 20px;
      width: 20%;
      .wrap {
        margin: 0 auto;
        width: 40px;
        height: 40px;
        background: #ffffff;
        border: 1px solid $borderColor;
        border-radius: 50%;
        line-height: 40px;
        text-align: center;
        font-weight: 500;
        cursor: pointer;
      }
      &.show.correct {
        .wrap {
          border-color: $color-green;
          background: $color-green;
          color: #fff;
        }
      }
      &.show.wrong {
        .wrap {
          border-color: $color-red;
          background: $color-red;
          color: #fff;
        }
      }
    }
  }
  .action {
    text-align: center;
  }
}
</style>