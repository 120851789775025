<template>
  <div v-loading="!listAllLoaed" :key="key" class="page-exam">
    <div class="table">
      <div class="table-cell">
        <answer-sheet ref="answer-sheet" v-if="listAllLoaed" :exam_id="exam_id" :version="version" :resultVisible.sync="resultVisible" :submitStatus.sync="submitStatus" @selectAnswer="selectAnswer" @answerSheetInited="answerSheetInited" @getResultSuccess="getResultSuccess" :questionList="questionList"></answer-sheet>
      </div>
      <div class="table-cell">
        <question ref="question" v-if="!resultVisible" :currentQuestion="currentQuestion" :mode="mode" :explainVisible.sync="explainVisible" @commitSuccess="commitSuccess" @toNext="toNext">
          <div slot="title" class="wrap clearfix">
            <span v-if="mode==2" class="right">
              <img class="icon" src="../../assets/images/icon-pause.png" alt="">倒计时 <span class="text-main">{{countdownTime}}</span>
            </span>
            [{{groupData.index+1}}/{{groupData.length}}] {{groupData.label}}
          </div>
        </question>
        <exam-result v-else :total_score="total_score" :exam_info="examData" @reAnswer="reAnswer"></exam-result>
      </div>
    </div>
  </div>
</template>
<script>
import answerSheet from './components/answerSheet'
import question from './components/question'
import examResult from './components/examResult'
import dic from '@/util/dic'
import { getExamQuestion, questionDetail, getExamPaper, setAnswer } from '@/api/exam'
export default {
  components: {
    answerSheet,
    question,
    examResult,
  },
  data() {
    return {
      questionTypeMap: dic.questionTypeMap,
      exam_id: '',
      mode: '',//1：练习 2：考试
      version: '',
      questionList: [],
      listAllLoaed: false,
      page: 1,

      questiontData: {},//id作为key的对象数组
      paperData: {},//type作为key的对象数组
      currentQuestion: {
        answer: '',
        id: '',
        question_id: '',
        answers: []
      },



      countdownTime: '',
      examData: {},

      groupData: {
        label: '',
        value: [],
        length: 0,
        index: 0
      },

      resultVisible: false,
      explainVisible: false,

      total_score: 0,//交卷后总分

      timer: null,

      key: new Date().getTime(),

      submitStatus: false
    }
  },
  computed: {
  },
  created() {
    this.init()
  },
  // 离开组件的时候触发
  beforeRouteLeave(to, from, next) {
    if (this.resultVisible) {
      next()
    } else {
      this.$confirm('返回将视为结束答题', '', {
        confirmButtonText: '结束答题',
        cancelButtonText: '继续答题',
        type: 'warning'
      }).then(() => {
        next();
      }).catch(() => {

      });
    }

  },
  methods: {
    init() {
      this.exam_id = this.$route.params.id
      this.mode = this.$route.query.type
      this.version = this.$route.query.version

      this.getExamData()
      this.getQuestionList()
    },
    answerSheetInited({ paperData, answer, groupData }) {
      //需保证getQuestionList已经全部过来了
      this.paperData = paperData

      this.selectAnswer({ answer, groupData })
      if (this.mode == 2) {
        this.countdown(this.examData.test_time)
      }
    },
    async getQuestion(question_id) {
      const { data } = await questionDetail({
        id: question_id,
        uniqid: this.$store.getters.uniqid
      })
      return data.question || {}
    },
    async getExamData() {
      const { data } = await getExamPaper({
        id: this.exam_id,
        uniqid: this.$store.getters.uniqid,
        type: ''
      })
      this.examData = data
    },
    async getQuestionList() {
      const { data } = await getExamQuestion({
        uniqid: this.$store.getters.uniqid,
        exam_id: this.exam_id,
        version: this.version,
        page: this.page,
        per_page: 1000
      })
      this.questionList = data.data || []
      this.listAllLoaed = true;
      this.questionList.forEach(item => {
        this.questiontData[item.question_id] = item;
      })
    },
    async setCurrent(answer) {
      let question_id = answer.question_id
      let question = this.questiontData[question_id].question
      if (!question) {
        question = await this.getQuestion(question_id);
      }
      this.currentQuestion = {
        ...question,
        ...answer,
        id: this.questiontData[question_id].id
      }

      //操作解析
      this.setExplainVisible(answer)
    },
    setExplainVisible(answer) {
      if (this.submitStatus) {
        //已经提交了状态下选择题目
        this.explainVisible = true
        this.resultVisible = false
      } else {
        //答题状态下选择题目
        if (answer.user_answer) {
          if (this.mode == 2) {
            if (!this.isChoice(this.currentQuestion.type)) {
              this.explainVisible = true
            } else {
              this.explainVisible = false
            }
          } else {
            this.explainVisible = this.$refs['question'] ? this.$refs['question'].explainStatus : false
          }
        } else {
          //每次操作答题卡切换题目后没有作答隐藏解析
          this.explainVisible = false
        }
      }
    },
    isChoice(type) {
      return [1, 2, 7, 8].includes(type)
    },
    selectAnswer({ answer, groupData }) {
      this.groupData = groupData;
      let question_id = answer.question_id
      if (!this.questiontData[question_id]) {
        //问题列表没有
        console.log('问题列表没有')
        return
      } else {
        this.setCurrent(answer)
      }
    },
    countdown(min) {
      if (this.timer) {
        clearInterval(this.timer)
      }
      let minutes = min, seconds = 0, time = min * 60;
      this.timer = setInterval(() => {
        if (time >= 0) {
          minutes = Math.floor(time / 60);
          seconds = Math.floor(time % 60);
          if (minutes < 10) minutes = '0' + minutes
          if (seconds < 10) seconds = '0' + seconds
          --time;
        } else {
          //交卷 
          this.$refs['answer-sheet'].submit()
          clearInterval(this.timer);
        }
        this.countdownTime = minutes + ':' + seconds
      }, 1000)
    },
    getResultSuccess(total_score) {
      this.resultVisible = true
      this.submitStatus = true
      this.total_score = total_score
    },
    commitSuccess({ explainStatus }) {
      // if (this.mode == 2) {
      //   if (!this.isChoice(this.currentQuestion.type)) {
      //     this.explainVisible = true
      //   } else {
      //     this.explainVisible = false
      //   }
      // } else {
      //   this.explainVisible = explainStatus
      // }
      this.explainVisible = explainStatus
      this.$refs['answer-sheet'].getPaperList()
    },
    async clearAnswer() {
      for (let i = 0; i < this.questionList.length; i++) {
        let ques = this.questionList[i]
        await setAnswer({
          id: ques.id,//注意这里是列表的id而不是题目的id
          contents: '',
          is_right: ''
        })
      }
    },
    async reAnswer() {
      //清空答案
      await this.clearAnswer();

      //初始化当前页
      this.resultVisible = false
      this.explainVisible = false
      this.submitStatus = false
      this.key = new Date().getTime() //只作用于子组件

    },
    toNext(question_id) {
      this.$refs['answer-sheet'].toNext(question_id)
    }
  }
}
</script>
<style lang="scss" scoped>
.page-exam {
  width: $contentWidth;
  max-width: 100%;
  margin: 20px auto 74px;
  .table {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .table-cell {
    display: table-cell;
    vertical-align: top;
    &:first-child {
      width: 320px;
    }
    &:last-child {
      padding-left: 10px;
    }
  }
}
</style>