<template>
  <div class="exam-result">
    <div class="score">
      <strong>{{total_score}}</strong>
      分
    </div>
    <div class="evaluate">{{getTip()}}</div>
    <div class="btn-group h-btn2-group">
      <button class="btn" @click="reAnswer">重新作答</button>
      <button class="btn" @click="$router.push('/')">返回首页</button>
    </div>
    <div class="tip">本次试卷总分{{exam_info.score}}分，{{exam_info.passing_score}}分及格</div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  props: {
    exam_info: Object,
    total_score: String | Number
  },
  methods: {
    getTip() {
      let score = this.total_score
      if (score < 90) {
        return '不要轻言放弃，否则对不起自己！'
      } else if (score < 110) {
        return '您的实力可以拿更高的分数，再加把劲！'
      } else if (score < 130) {
        return '恭喜，稳住就能赢！'
      } else {
        return '恭喜，您的实力已经登峰造极啦！'
      }
    },
    reAnswer() {
      this.$emit('reAnswer')
    }
  }

}
</script>
<style lang="scss" scoped>
.exam-result {
  position: relative;
  height: 800px;
  background: #fff;
  padding-top: 258px;
  text-align: center;
  .score {
    font-size: 24px;
    strong {
      color: $mainColor;
      font-size: 60px;
      vertical-align: text-bottom;
      margin-right: 10px;
    }
  }
  .evaluate {
    margin-top: 26px;
    font-size: 24px;
    font-weight: 500;
  }
  .btn-group {
    margin-top: 98px;
  }
  .tip {
    position: absolute;
    bottom: 38px;
    left: 0;
    width: 100%;
    color: $textLightColor;
  }
}
</style>